<template>
  <v-container fluid>
    <v-row>
      <v-col md="4" lg="3">
        <div class="mx-dashboard-column mx-first-child-column">
          <div class="mx-dashboard-column-header" style="border-bottom-color: #F6D264;">
            <h3>В Приемной</h3>
          </div>
          <div class="mx-dashboard-card" v-for="item in expects" :key="item.id">
            <div class="mx-dashboard-card-top">
              <div class="mx-dashboard-card-top__date">
                <div class="card-date">
                  <strong>{{ item.date.day }}</strong>
                  <span>{{ item.date.month }}</span>
                </div>
                <div class="card-time">{{ item.date.time }}</div>
              </div>
              <div class="mx-dashboard-card-top__content">
                <div class="content-title">
                  {{ item.title }}
                </div>
                <div class="content-times">
                  <div class="content-time" v-for="time in item.times" :key="time.name">
                    <div class="content-time-title">{{ time.name }}:</div>
                    <div class="content-time-text">
                      <strong>{{ time.time }}</strong>
                      <span v-if="time.reg !== null">({{ time.reg }} МИН.)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-dashboard-card-bottom">
              <span class="mx-dashboard-card-bottom-service-percent" :style="`width: ${getServicePercent(getServices(item.services))}%;`"></span>
              <div class="mx-dashboard-card-bottom__service" v-if="item.service_status">
                <div
                    class="mx-service-item"
                    v-for="service in getServices(item.services)"
                    :key="service.id"
                    :class="{'active': service.status}"
                >

                  {{ getServiceName(service.long_name) }}
                </div>
                <div class="mx-service-item-more" v-if="item.services.length > 3">
                  <span>+</span>
                  <span>{{ item.services.length - 3 }}</span>
                </div>
              </div>
              <div class="mx-dashboard-card-bottom__price" v-else>
                <span>{{ item.price }}</span>
                <v-icon size="14">fas fa-ruble-sign</v-icon>
              </div>
              <div class="mx-dashboard-card-bottom__right">
                <div class="mx-labels">
                  <div class="mx-label mx-label__warning" v-if="item.priority === 1">
                    <span>Важно</span>
                  </div>
                  <div class="mx-label mx-label__red" v-if="item.priority === 2">
                    <span>Срочно</span>
                  </div>
                  <div class="mx-label mx-label__service" v-if="item.in_service.status">
                    <span>{{ item.in_service.name }}</span>
                  </div>
                </div>
                <div class="mx-btns">
                  <div class="mx-btn mx-btn__pay" v-if="item.status === 'to_pay'">
                    <v-icon size="14">fas fa-ruble-sign</v-icon>
                  </div>
                  <div class="mx-btn mx-btn__edit">
                    <v-icon size="10">fas fa-pen</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-column-border-height"></div>
        </div>
      </v-col>
      <v-col md="4" lg="3">
        <div class="mx-dashboard-column">
          <div class="mx-dashboard-column-header" style="border-bottom-color: #ABD7B2;">
            <h3>У специалиста</h3>
          </div>
          <div class="mx-dashboard-card" v-for="item in in_work" :key="item.id">
            <div class="mx-dashboard-card-top">
              <div class="mx-dashboard-card-top__date">
                <div class="card-date">
                  <strong>{{ item.date.day }}</strong>
                  <span>{{ item.date.month }}</span>
                </div>
                <div class="card-time">{{ item.date.time }}</div>
              </div>
              <div class="mx-dashboard-card-top__content">
                <div class="content-title">
                  {{ item.title }}
                </div>
                <div class="content-times">
                  <div class="content-time" v-for="time in item.times" :key="time.name">
                    <div class="content-time-title">{{ time.name }}:</div>
                    <div class="content-time-text">
                      <strong>{{ time.time }}</strong>
                      <span v-if="time.reg !== null">({{ time.reg }} МИН.)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-dashboard-card-bottom">
              <span class="mx-dashboard-card-bottom-service-percent" :style="`width: ${getServicePercent(getServices(item.services))}%;`"></span>
              <div class="mx-dashboard-card-bottom__service" v-if="item.service_status">
                <div
                    class="mx-service-item"
                    v-for="service in getServices(item.services)"
                    :key="service.id"
                    :class="{'active': service.status}"
                >

                  {{ getServiceName(service.long_name) }}
                </div>
                <div class="mx-service-item-more" v-if="item.services.length > 3">
                  <span>+</span>
                  <span>{{ item.services.length - 3 }}</span>
                </div>
              </div>
              <div class="mx-dashboard-card-bottom__price" v-else>
                <span>{{ item.price }}</span>
                <v-icon size="14">fas fa-ruble-sign</v-icon>
              </div>
              <div class="mx-dashboard-card-bottom__right">
                <div class="mx-labels">
                  <div class="mx-label mx-label__warning" v-if="item.priority === 1">
                    <span>Важно</span>
                  </div>
                  <div class="mx-label mx-label__red" v-if="item.priority === 2">
                    <span>Срочно</span>
                  </div>
                  <div class="mx-label mx-label__service" v-if="item.in_service.status">
                    <span>{{ item.in_service.name }}</span>
                  </div>
                </div>
                <div class="mx-btns">
                  <div class="mx-btn mx-btn__pay" v-if="item.status === 'to_pay'">
                    <v-icon size="14">fas fa-ruble-sign</v-icon>
                  </div>
                  <div class="mx-btn mx-btn__edit">
                    <v-icon size="10">fas fa-pen</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-column-border-height"></div>
        </div>
      </v-col>
      <v-col md="4" lg="3">
        <div class="mx-dashboard-column">
          <div class="mx-dashboard-column-header" style="border-bottom-color: #E35850;">
            <h3>К Оплате</h3>
          </div>
          <div class="mx-dashboard-card" v-for="item in to_pay" :key="item.id">
            <div class="mx-dashboard-card-top">
              <div class="mx-dashboard-card-top__date">
                <div class="card-date">
                  <strong>{{ item.date.day }}</strong>
                  <span>{{ item.date.month }}</span>
                </div>
                <div class="card-time">{{ item.date.time }}</div>
              </div>
              <div class="mx-dashboard-card-top__content">
                <div class="content-title">
                  {{ item.title }}
                </div>
                <div class="content-times">
                  <div class="content-time" v-for="time in item.times" :key="time.name">
                    <div class="content-time-title">{{ time.name }}:</div>
                    <div class="content-time-text">
                      <strong>{{ time.time }}</strong>
                      <span v-if="time.reg !== null">({{ time.reg }} МИН.)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-dashboard-card-bottom">
              <span class="mx-dashboard-card-bottom-service-percent" :style="`width: ${getServicePercent(getServices(item.services))}%;`"></span>
              <div class="mx-dashboard-card-bottom__service" v-if="item.service_status">
                <div
                    class="mx-service-item"
                    v-for="service in getServices(item.services)"
                    :key="service.id"
                    :class="{'active': service.status}"
                >

                  {{ getServiceName(service.long_name) }}
                </div>
                <div class="mx-service-item-more" v-if="item.services.length > 3">
                  <span>+</span>
                  <span>{{ item.services.length - 3 }}</span>
                </div>
              </div>
              <div class="mx-dashboard-card-bottom__price" v-else>
                <span>{{ item.price }}</span>
                <span style="font-weight: 500;">₽</span>
<!--                <v-icon size="14">fas fa-ruble-sign</v-icon>-->
              </div>
              <div class="mx-dashboard-card-bottom__right">
                <div class="mx-labels">
                  <div class="mx-label mx-label__warning" v-if="item.priority === 1">
                    <span>Важно</span>
                  </div>
                  <div class="mx-label mx-label__red" v-if="item.priority === 2">
                    <span>Срочно</span>
                  </div>
                  <div class="mx-label mx-label__service" v-if="item.in_service.status">
                    <span>{{ item.in_service.name }}</span>
                  </div>
                </div>
                <div class="mx-btns">
                  <div class="mx-btn mx-btn__pay" v-if="item.status === 'to_pay'">
<!--                    <v-icon size="14">fas fa-ruble-sign</v-icon>-->
                    <span style="font-weight: 400; color: #ffffff;">₽</span>
                  </div>
                  <div class="mx-btn mx-btn__edit">
                    <v-icon size="10">fas fa-pen</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mx-column-border-height"></div>
        </div>
      </v-col>
      <v-col md="4" lg="3">
        <div class="mx-dashboard-column mx-last-child-column">
          <div class="mx-dashboard-column-header" style="border-bottom-color: #81909C;">
            <h3>Отчет за сегодня</h3>

            <strong>
              {{ getDaySum }}
              <span style="font-weight: 500; font-size: 16px; margin-left: -5px;">₽</span>
<!--              <v-icon size="14">fas fa-ruble-sign</v-icon>-->
            </strong>
          </div>
          <div class="mx-dashboard-card" v-for="item in completed" :key="item.id">
            <div class="mx-dashboard-card-top">
              <div class="mx-dashboard-card-top__date">
                <div class="card-date">
                  <strong>{{ item.date.day }}</strong>
                  <span>{{ item.date.month }}</span>
                </div>
                <div class="card-time">{{ item.date.time }}</div>
              </div>
              <div class="mx-dashboard-card-top__content">
                <div class="content-title">
                  {{ item.title }}
                </div>
                <div class="content-times">
                  <div class="content-time" v-for="time in item.times" :key="time.name">
                    <div class="content-time-title">{{ time.name }}:</div>
                    <div class="content-time-text">
                      <strong>{{ time.time }}</strong>
                      <span v-if="time.reg !== null">({{ time.reg }} МИН.)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-dashboard-card-bottom">
              <span class="mx-dashboard-card-bottom-service-percent" :style="`width: ${getServicePercent(getServices(item.services))}%;`"></span>
              <div class="mx-dashboard-card-bottom__service" v-if="item.service_status">
                <div
                    class="mx-service-item"
                    v-for="service in getServices(item.services)"
                    :key="service.id"
                    :class="{'active': service.status}"
                >

                  {{ getServiceName(service.long_name) }}
                </div>
                <div class="mx-service-item-more" v-if="item.services.length > 3">
                  <span>+</span>
                  <span>{{ item.services.length - 3 }}</span>
                </div>
              </div>
              <div class="mx-dashboard-card-bottom__price" v-else>
                <span>{{ item.price }}</span>
                <v-icon size="14">fas fa-ruble-sign</v-icon>
              </div>
              <div class="mx-dashboard-card-bottom__right">
                <div class="mx-labels">
                  <div class="mx-label mx-label__warning" v-if="item.priority === 1">
                    <span>Важно</span>
                  </div>
                  <div class="mx-label mx-label__red" v-if="item.priority === 2">
                    <span>Срочно</span>
                  </div>
                  <div class="mx-label mx-label__service" v-if="item.in_service.status">
                    <span>{{ item.in_service.name }}</span>
                  </div>
                </div>
                <div class="mx-btns">
                  <div class="mx-btn mx-btn__pay" v-if="item.status === 'to_pay'">
                    <v-icon size="14">fas fa-ruble-sign</v-icon>
                  </div>
                  <div class="mx-btn mx-btn__edit">
                    <v-icon size="10">fas fa-pen</v-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "WartezimmerView",
  data() {
    return {
      expects: [
        {
          id: 2,
          date: {
            day: '14',
            month: 'Май',
            time: '16:00'
          },
          title: 'Марина Когничуг',
          times: [
            {name: 'В приемной', time: '16:15', reg: '-15', status: 1},
            {name: 'У специалиста', time: '--:--', reg: null, status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 0},
            {id: 2, long_name: 'Сушка волос', name: 'фн', status: 0},
            {id: 3, long_name: 'Шампунь', name: 'Ша', status: 0},
          ],
          priority: 0,
          service_status: 1,
          in_service: {long_name: 'Кресло 4', name: 'Кр-4', status: false},
          status: 'expects',
          price: '2800'
        },
        {
          id: 3,
          date: {
            day: '14',
            month: 'Май',
            time: '16:30'
          },
          title: 'Сергей Повлеченко',
          times: [
            {name: 'В приемной', time: '--:--', reg: null, status: 1},
            {name: 'У специалиста', time: '--:--', reg: null, status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 0},
            {id: 2, long_name: 'Сушка волос', name: 'св', status: 0},
          ],
          priority: 2,
          service_status: 1,
          in_service: {long_name: '', name: '', status: false},
          status: 'expects',
          price: '1375'
        }
      ],
      in_work: [
        {
          id: 1,
          date: {
            day: '14',
            month: 'Май',
            time: '15:30'
          },
          title: 'Екатерина Фллор',
          times: [
            {name: 'В приемной', time: '15:15', reg: '30', status: 1},
            {name: 'У специалиста', time: '15:42', reg: '10', status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 1},
            {id: 2, long_name: 'Сушка волос', name: 'фн', status: 0},
            {id: 3, long_name: 'Укладка прически', name: 'пк', status: 1},
            {id: 4, long_name: 'Шампунь', name: 'шм', status: 1},
          ],
          priority: 1,
          service_status: 1,
          in_service: {long_name: 'Кабинет 17', name: 'Каб-17', status: true},
          status: 'in_work', // expects = ожидает, in_work = в работе на обслуживании, to_pay = к оплате, completed = завершено
          price: '2365'
        },
      ],
      to_pay: [
        {
          id: 1,
          date: {
            day: '14',
            month: 'Май',
            time: '13:45'
          },
          title: 'Виктория Хлобыстина',
          times: [
            {name: 'В приемной', time: '13:56', reg: '10', status: 1},
            {name: 'У специалиста', time: '14:00', reg: '36', status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 1},
            {id: 2, long_name: 'Сушка волос', name: 'фн', status: 1},
            {id: 3, long_name: 'Укладка прически', name: 'пк', status: 1},
            {id: 4, long_name: 'Шампунь', name: 'шм', status: 1},
          ],
          priority: 1,
          service_status: 0,
          in_service: {long_name: 'Кабинет 17', name: 'Каб-17', status: true},
          status: 'to_pay', // expects = ожидает, in_work = в работе на обслуживании, to_pay = к оплате, completed = завершено
          price: '2365'
        },
      ],
      completed: [
        {
          id: 1,
          date: {
            day: '14',
            month: 'Май',
            time: '10:30'
          },
          title: 'Михаил Смирнов',
          times: [
            {name: 'В приемной', time: '10:00', reg: '30', status: 1},
            {name: 'У специалиста', time: '10:45', reg: '40', status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 1},
            {id: 2, long_name: 'Сушка волос', name: 'фн', status: 1},
            {id: 3, long_name: 'Укладка прически', name: 'пк', status: 1},
            {id: 4, long_name: 'Шампунь', name: 'шм', status: 1},
          ],
          priority: 1,
          in_service: {long_name: 'Кабинет 17', name: 'Каб-17', status: false},
          service_status: 0,
          status: 'completed', // expects = ожидает, in_work = в работе на обслуживании, to_pay = к оплате, completed = завершено
          price: '1356'
        },
        {
          id: 2,
          date: {
            day: '14',
            month: 'Май',
            time: '12:15'
          },
          title: 'Василий Петров',
          times: [
            {name: 'В приемной', time: '12:00', reg: '30', status: 1},
            {name: 'У специалиста', time: '12:20', reg: '10', status: 1},
          ],
          services: [
            {id: 1, long_name: 'Стрижка', name: 'ст', status: 1},
            {id: 2, long_name: 'Шампунь', name: 'шм', status: 1},
          ],
          priority: 0,
          in_service: {long_name: 'Кабинет 17', name: 'Каб-17', status: false},
          service_status: 0,
          status: 'completed', // expects = ожидает, in_work = в работе на обслуживании, to_pay = к оплате, completed = завершено
          price: '4960'
        },
      ],
    }
  },
  computed: {
    getDaySum() {
      return this.completed.map(item => parseInt(item.price)).reduce((prev, curr) => prev + curr, 0)
    }
  },
  methods: {
    getServices(services) {
      return services.slice(0, 3)
    },
    getServiceName(service) {
      const sr = service.split(' ')
      if (sr.length > 1) {
        return sr[0][0] + sr[1][0]
      }
      return service.slice(0, 2)
    },
    getServicePercent(services) {
      const active_services = services.filter(service => service.status === 1)
      return parseInt((active_services.length / services.length * 100))
    }
  }
}
</script>

<style lang="scss">

$border: rgb(219, 229, 237);
//$color-text: #38424B;
$color-text: #404040;
//$color-text-light: #9CA9B4;
$color-text-light: #808080;
$color-text-light-2: #81909C;

$sidebar-bg-dark: #4B545B;

$red: #E35850;
$blue-light: #65BFDB;
//$accent: #2587A5;
$accent: #3a95d9;
$current: #ABD7B2;
$warning: #F6D264;
$yellow: #E7B00B;
$accept: #60AC6C;
$green: #1F7E67;
$grey: #c2c2c2;

.mx-dashboard-column {
  position: relative;

  //font-family: SFMono-mx, sans-serif !important;
  //font-family: 'Montserrat', sans-serif;

  //font-family: LabGrotesque-mx, sans-serif !important;
  //letter-spacing: -0.01em;
  //word-spacing: -0.28em;

  .mx-column-border-height {
    position: absolute;
    min-height: calc(100vh - 70px);
    width: 1px;
    right: -12px;
    top: 0;
    background: rgba($border, 0.5);
  }
  &-header {
    padding-top: 20px;
    border-bottom: 4px solid;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    h3 {
      margin-bottom: 8px;
      padding-left: 20px;
    }
    strong {
      font-size: 18px;
      margin-top: -4px;
      margin-right: 10px;
    }
  }
}

.mx-dashboard-card {
  color: $color-text;
  background: #ffffff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 16px 22px;
  margin-top: 8px;
  &-top {
    display: flex;
    padding-bottom: 16px;

    &__date {
      border: 1px solid $border;
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      border-radius: 2px;
      .card-date {
        background: #F1F9FF;
        padding: 4px 8px;
        strong {
          font-size: 14px;
          font-weight: 900;
          margin-right: 3px;
        }
        span {
          text-transform: uppercase;
          font-size: 12px;
        }
      }
      .card-time {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
      }
    }
    &__content {
      .content-title {
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 2px;
      }
      .content-times {
        display: flex;
        .content-time {
          margin-right: 30px;
          &:last-child {
            margin-right: 0;
          }
          &-title {
            font-weight: 700;
            color: $color-text-light;
            font-size: 12px;
            margin-bottom: -4px;
          }
          &-text {
            strong {
              font-size: 14px;
              margin-right: 4px;

            }
            span {
              color: $color-text-light;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  &-bottom {
    border-top: 1px solid #DBE5ED;
    padding-top: 14px;
    display: flex;
    justify-content: space-between;
    position: relative;
    letter-spacing: 0 !important;
    &-service-percent {
      position: absolute;
      height: 3px;
      background: $accent;
      top: -2px;
    }
    &__service {
      display: flex;
      margin-right: auto;
      .mx-service-item {
        border: 1px solid $border;
        color: $color-text-light-2;
        margin-right: 5px;
        padding: 2px  10px;
        border-radius: 2px;
        font-size: 11px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        &-more {
          padding: 2px  10px;
          border-radius: 3px;
          background: $border;
          color: $color-text-light-2;
          font-weight: 700;
          font-size: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.active {
          border-color: $accent;
          color: $accent;
        }
      }
    }
    &__price {
      font-weight: 700;
      font-size: 20px;
      margin: -3px 20px -3px 4px;
      width: 100%;
      span {
        margin-right: 4px;
      }
      .v-icon {
        margin-top: 2px;
        color: $color-text;
      }
    }
    &__right {
      display: flex;
      .mx-btns {
        display: flex;
        .mx-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          margin-left: 5px;
          cursor: pointer;
          i {
            color: #ffffff;
          }
          &__edit {
            width: 38px;
            height: 28px;
            background: $accent;
            border: 1px solid $accent;
            transition: all 150ms ease-in;
            &:hover {
              background: darken($accent, 7%);
              border-color: darken($accent, 7%);
            }
          }
          &__pay {
            width: 56px;
            height: 28px;
            font-weight: 400;
            background: $accent;
            border: 1px solid $accent;
            &:hover {
              background: darken($accent, 7%);
              border-color: darken($accent, 7%);
            }
          }
        }
      }
      .mx-labels {
        display: flex;
        .mx-label {
          display: flex;
          align-items: center;
          height: 28px;
          font-size: 13px;
          border: 1px solid;
          border-radius: 3px;
          margin-left: 5px;
          padding: 0 8px;
          &__warning {
            border-color: $yellow;
            color: $yellow;
          }
          &__red {
            border-color: $red;
            color: $red;
          }
          &__default {
            border-color: $color-text-light;
            color: $color-text-light-2;
          }
          &__service {
            padding: 0 12px;
            border-color: $color-text-light;
            color: $color-text-light-2;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}


</style>